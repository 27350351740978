body {
  margin: 0;
  font-family: "Open Sans",sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
}

#root{
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.customLink {
  text-decoration: none;
  margin-right: 20px;
  font-size: 14px;
  color: black;
}

.activeCustomLink {
  font-weight: 400;
  color: #7c60ff;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px white inset;
}
